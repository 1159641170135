import React, { Component } from "react";
import axios from "axios";
import { Map, GoogleApiWrapper, Marker, Circle } from "google-maps-react";
import "react-dragswitch/dist/index.css";
import Fade from "react-reveal/Fade";
import FeatherIcon from "feather-icons-react";
import { Iconly } from "react-iconly";
import Axios from "axios";
import { Form, Input, Space, Select, InputNumber } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import RouteOptimization from "./Plugins/RouteOptimization";

import {
  Button,
  SelectMenu,
  Avatar,
  Badge,
  Pill,
  Position,
  Spinner,
  Alert,
  SearchInput,
} from "evergreen-ui";

import Moment from "react-moment";
import * as moment from "moment";
import { Switch, Tooltip } from "antd";
import GoogleMapReact from "google-map-react";
import Fuse from "fuse.js";
import GaugeChart from "react-gauge-chart";
import mapStyle from "../Helpers/mapStyle";
import { Redirect } from "react-router";
import "antd/dist/antd.css";
import { Chart } from "react-google-charts";
import { ArcGauge } from "@progress/kendo-react-gauges";
import Echo from "laravel-echo";
import { Tasks } from "./Tasks";
import Agents from "./Agents";
import { Drawer, Divider } from "antd";
import CreateTask from "./CreateTask";
import Footer from "./Footer";

const mapStyles = {
  width: "70%",
  height: "100%",
};
const { Option } = Select;

const exampleMapStyles = mapStyle;

export class Portal extends Component {
  static contextTypes = {
    router: () => null,
  };

  state = {
    refresh_data_count: 0,
    delivery_guys: [],
    cities: [],
    warehouses: [],
    customers: [],
    selectedCity: { label: localStorage.getItem("all_team"), value: "all" },
    orders: [],
    on_going_orders: [],
    unassigned_orders: [],
    delivery_gauage: 0,
    second_delivery_gauage: 0,
    third_delivery_gauage: 0,
    loading: true,
    filterdDG: [],
    filterdOrders: [],
    markedOrders: [],
    selectedDeliveryGuy: null,
    selectedOrder: null,
    deliveryPop: false,
    batchMode: false,
    data_feeds: [],
    showLatest: localStorage.getItem("show_latest"),
    showOnGoing: localStorage.getItem("showOnGoing"),
    showLateOrder: false,
    show_rejections: false,
    free_delivery_guys: 0,
    busy_delivery_guys: 0,
    center: {
      lat: 10.976,
      lng: 76.2254,
    },
    zoom: 1,
    tab: false,
    autoModeLoading: false,
    autoMode: true,
    map_layers: false,
    freeAgentsArray: [],
    busyAgentsArray: [],
    products: [],
  };

  mapChangeLocation = (location) => {
    const map = this.ref;
    map.panTo(location);
    map.setZoom(15);
  };
  componentDidMount() {
    if (!localStorage.getItem("lang")) {
      console.log("hoi");
      Axios.post(
        `https://fleet.howincloud.com/api/v1/${sessionStorage.getItem(
          "tenant"
        )}/get-translations`,
        {
          lang: "en",
        }
      ).then((response) => {
        if (response.data.lang) {
          const langs = response.data.lang.data;
          localStorage.setItem("lang", "en");
          langs.forEach((item) => {
            localStorage.setItem(item.key, item.value);
          });
        }
        window.location.reload();
      });
    }

    // this.changeLanguage();
    this.callApis(this.state.selectedCity);
    this.interval = setInterval(
      () => this.callApis(this.state.selectedCity),
      30000
    );

    window.echo = new Echo({
      broadcaster: "socket.io",
      host: "https://fleet.howincloud.com:6001",
      logToConsole: true,
      transports: ["websocket"],
    });

    window.echo
      .channel("fleet_database_" + sessionStorage.getItem("tenant"))
      .listen(".order-event", (data) => {
        this.incrementRefreshCount();
      });
  }

  changeLanguage = () => {
    if (localStorage.getItem("lang") == "en") {
      var lang = "ar";
    } else {
      var lang = "en";
    }

    Axios.post(
      `https://fleet.howincloud.com/api/v1/${sessionStorage.getItem(
        "tenant"
      )}/get-translations`,
      {
        lang: lang,
      }
    ).then((response) => {
      if (response.data.lang) {
        const langs = response.data.lang.data;
        localStorage.setItem("lang", lang);
        langs.forEach((item) => {
          localStorage.setItem(item.key, item.value);
        });
      }
      window.location.reload();
    });
  };

  incrementRefreshCount = () => {
    this.setState(
      { refresh_data_count: 1 + this.state.refresh_data_count },
      console.log(this.state.refresh_data_count)
    );
  };

  componentWillUnmount() {
    clearInterval(this.interval);
    window.echo.disconnect();
  }

  countFreeDeliverGuys = () => {
    const dgs = this.state.delivery_guys;
    let free = 0;
    let freeAgentsArray = [];
    let busyAgentsArray = [];

    dgs.map((dg) => {
      if (
        dg.accept_deliveries.length == 0 ||
        dg.accept_deliveries.length == undefined
      ) {
        free += 1;
        if (dg.on_duty == true) {
          dg.busy = false;
          freeAgentsArray.push(dg);
        }
      }
    });

    let busy = 0;

    dgs.map((dg) => {
      if (
        dg.accept_deliveries.length != 0 &&
        dg.accept_deliveries.length != undefined
      ) {
        busy += 1;
        if (dg.on_duty == true) {
          dg.busy = true;
          busyAgentsArray.push(dg);
        }
      }
    });

    this.setState({
      free_delivery_guys: free,
      busy_delivery_guys: busy,
      freeAgentsArray: freeAgentsArray,
      busyAgentsArray: busyAgentsArray,
    });

    let unassigned_order_count = 0;
    this.state.orders.map((order) => {
      if (order.auto_assign_status !== "assigned") {
        unassigned_order_count += 1;
      }
    });

    let total_agents = parseFloat(free) + parseFloat(busy);
    let unassigned_to_free =
      (parseFloat(unassigned_order_count) / parseFloat(free)) * 100;
    let unassigned_to_free_max =
      (parseFloat(unassigned_order_count) / parseFloat(free * 2)) * 100;
    let unassigned_to_max_capacity =
      (parseFloat(unassigned_order_count) / (total_agents * 2)) * 100;
    // console.log(unassigned_to_free,unassigned_to_max_capacity)
    this.setState({
      unassigned_to_free: unassigned_to_free.toFixed(0),
      unassigned_to_max_capacity: unassigned_to_max_capacity.toFixed(0),
      unassigned_to_free_max: unassigned_to_free_max.toFixed(0),
    });
  };

  addToMarked = (order) => {
    if (this.state.batchMode) {
      if (this.checkSelectedOrder(order)) {
        this.setState({
          markedOrders: this.state.markedOrders.filter(function (od) {
            return od.id !== order.id;
          }),
        });
      } else {
        let filterdOrders = this.state.markedOrders;
        this.setState({
          markedOrders: [...filterdOrders, order],
        });
      }
    }
  };

  callApis = (item) => {
    this.setState({
      apiLock: true,
    });
    axios
      .post(
        `https://fleet.howincloud.com/api/v1/${sessionStorage.getItem(
          "tenant"
        )}/get-client-data`,
        {
          city: item.value,
          token: sessionStorage.getItem("auth_token"),
          client_token: sessionStorage.getItem("client_token"),
          domain_name: sessionStorage.getItem("domain"),
        }
      )
      .then((response) => {
        if (response.data.code === 401) {
          this.context.router.history.push("/session-expierd");
        } else {
          let ongoingorders = [];
          let unassigned_orders = [];
          response.data.orders_data.map((order, index) => {
            if (order.order_status_id == 6 || order.order_status_id == 7 || order.order_status_id == 12) {
              ongoingorders.push(order);
            }

            if (order.auto_assign_status != "assigned") {
              unassigned_orders.push(order);
            }
          });

          this.setState({
            orders: response.data.orders_data,
            deliveryPop: false,
            orderPop: false,
            filterdOrders: response.data.orders_data,
            delivery_guys: response.data.agent_data &&  response.data.agent_data?.length > 0 ? response.data.agent_data : response.data.offline_agents,
            filterdDG: response.data.agent_data,
            data_feeds: response.data.feed_data,
            cities: response.data.city_data,
            warehouses: response.data.warehouses,
            customers: response.data.customers,
            loading: false,
            refresh_data_count: 0,
            on_going_orders: ongoingorders,
            offline_agents: response.data.offline_agents,
            unassigned_orders: unassigned_orders,
            products: response.data.products
          });

          // if (response.data.autoMode === 0) {
          //   this.setState({
          //     autoMode: false,
          //   });
          // } else {
          //   this.setState({
          //     autoMode: true,
          //   });
          // }

          this.countFreeDeliverGuys();

          this.setState({
            apiLock: false,
            loading: false,
          });
        }
      });
  };

  executeBatchOrder = (batch) => {
    this.setState({
      loading: true,
    });
    axios
      .post(
        `https://fleet.howincloud.com/api/v1/${sessionStorage.getItem(
          "tenant"
        )}/execute-batch`,
        {
          orders: this.state.markedOrders,
          delivery_guy: this.state.selectedDeliveryGuy,
          token: sessionStorage.getItem("auth_token"),
          client_token: sessionStorage.getItem("client_token"),
          domain_name: sessionStorage.getItem("domain"),
          batch: batch,
        }
      )
      .then((response) => {
        this.callApis(this.state.selectedCity);
        this.setState({
          markedOrders: [],
          loading: false,
        });
      });
  };

  retryAutoAssign = (order) => {
    this.setState({
      loading: true,
    });
    axios
      .post(
        `https://fleet.howincloud.com/api/v1/${sessionStorage.getItem(
          "tenant"
        )}/retry-auto-assign`,
        {
          id: order.id,
          token: sessionStorage.getItem("auth_token"),
          client_token: sessionStorage.getItem("client_token"),
          domain_name: sessionStorage.getItem("domain"),
        }
      )
      .then((response) => {
        this.callApis(this.state.selectedCity);
        this.setState({
          markedOrders: [],
        });
      });
  };

  manaualOrderComplete = (order) => {
    this.setState({
      loading: true,
    });
    axios
      .post(
        `https://fleet.howincloud.com/api/v1/${sessionStorage.getItem(
          "tenant"
        )}/deliver-order-manual`,
        {
          id: order.id,
          admin_code: 7998,
          token: sessionStorage.getItem("auth_token"),
          client_token: sessionStorage.getItem("client_token"),
          domain_name: sessionStorage.getItem("domain"),
        }
      )
      .then((response) => {
        this.callApis(this.state.selectedCity);
        this.setState({
          markedOrders: [],
        });
      });
  };

  markAsInActive = (agent) => {
    this.setState({
      loading: true,
    });
    axios
      .post(
        `https://fleet.howincloud.com/api/v1/${sessionStorage.getItem(
          "tenant"
        )}/mark-as-inactive`,
        {
          id: agent.id,
          token: sessionStorage.getItem("auth_token"),
          client_token: sessionStorage.getItem("client_token"),
          domain_name: sessionStorage.getItem("domain"),
        }
      )
      .then((response) => {
        this.callApis(this.state.selectedCity);
        this.setState({
          markedOrders: [],
        });
      });
  };
  markAsActive = (agent) => {
    this.setState({
      loading: true,
    });
    axios
      .post(
        `https://fleet.howincloud.com/api/v1/${sessionStorage.getItem(
          "tenant"
        )}/mark-as-active`,
        {
          id: agent.id,
          token: sessionStorage.getItem("auth_token"),
          client_token: sessionStorage.getItem("client_token"),
          domain_name: sessionStorage.getItem("domain"),
        }
      )
      .then((response) => {
        this.callApis(this.state.selectedCity);
        this.setState({
          markedOrders: [],
        });
      });
  };

  dispatchUpdateToClient = (order) => {
    this.setState({
      loading: true,
    });
    axios
      .post(
        `https://fleet.howincloud.com/api/v1/${sessionStorage.getItem(
          "tenant"
        )}/update-data-to-client`,
        {
          id: order.id,
          token: sessionStorage.getItem("auth_token"),
          client_token: sessionStorage.getItem("client_token"),
          domain_name: sessionStorage.getItem("domain"),
        }
      )
      .then((response) => {
        this.callApis(this.state.selectedCity);
      });
  };

  toogleAutoMode = (auto) => {
    axios
      .post(
        `https://fleet.howincloud.com/api/v1/${sessionStorage.getItem(
          "tenant"
        )}/toogle-auto-mode`,
        {
          auto: this.state.autoMode,
          token: sessionStorage.getItem("auth_token"),
          client_token: sessionStorage.getItem("client_token"),
        }
      )
      .then((response) => {
        // this.callApis(this.state.selectedCity);

        if (response.data === 0) {
          this.setState({
            autoMode: false,
            autoModeLoading: false,
          });
        } else {
          this.setState({
            autoMode: true,
            autoModeLoading: false,
          });
        }
      });
  };

  setMapRoute = (data) => {
    // console.log(this.props.google.maps);

    var routeColors = [
      "#0074D9", // blue
      "#FF851B", // orange
      "#B10DC9", // purple
      "#2ECC40", // green
      "#FFDC00", // yellow
      "#F012BE", // fuchsia
      "#01FF70", // lime
      "#999999", // gray
      "#001f3f", // navy
      "#FF4136", // red
      "#85144b", // maroon
      "#3D9970", // olive
      "#39CCCC", // teal
    ];
    const route = new this.props.google.maps.Polyline({
      path: data,
      geodesic: true,
      strokeColor:
        routeColors[[Math.floor(Math.random() * routeColors.length)]],
      strokeOpacity: 0.7,
      strokeWeight: 10,
    });

    route.setMap(this.ref);
  };

  deployDeliveryGuyPop = (dg) => {
    // const cityCircle = new this.props.google.maps.Circle({
    //   strokeColor: "#FF0000",
    //   strokeOpacity: 0.8,
    //   strokeWeight: 2,
    //   fillColor: "#FF0000",
    //   fillOpacity: 0.1,
    //   center: { lat: 10.9760, lng: 76.2254 },
    //   radius: 1000,
    // });

    // cityCircle.setMap(this.ref);
    // this.state.agent_store.setMap(null);

    // this.props.google.maps.Point(48, 48);
    this.setState({
      selectedDeliveryGuy: dg,
      deliveryPop: true,
      zoom: 15,
      center: {
        lat: dg.latitude,
        lng: dg.longitude,
      },
    });
  };

  setUnassignedOrders = (orders) => {
    this.setState({ unassigned_orders: orders });
  };

  deployOrderPop = (order) => {
    // this.setMapRoute(true);
    // Axios.post(`https://fleet.howincloud.com/api/v1/${sessionStorage.getItem("tenant")}/get-geo-data`, {
    //     id: order.id,
    //     token: sessionStorage.getItem("auth_token"),
    //     client_token: sessionStorage.getItem("client_token"),
    //   })
    //     .then(response => {
    //       if(response.data.success) {

    //         var agent_store = new this.props.google.maps.Polyline({
    //           path: response.data.agent_store,
    //           geodesic: true,
    //           strokeColor: 'red',
    //           strokeOpacity: 1.0,
    //           strokeWeight: 5
    //           });

    //           agent_store.setMap(this.ref);

    //          var flightPlanCoordinates2 = response.data.store_customer;

    //         var store_customer = new this.props.google.maps.Polyline({
    //           path: flightPlanCoordinates2,
    //           geodesic: true,
    //           strokeColor: 'blue',
    //           strokeOpacity: 1.0,
    //           strokeWeight: 5
    //           });

    //           store_customer.setMap(this.ref);

    //          this.setState({
    //            'agent_store': agent_store,
    //            'store_customer': store_customer
    //          });

    //       }

    //     });

    if (!this.state.batchMode) {
      this.setState({
        selectedOrder: order,
        orderPop: true,
      });
    }
    this.addToMarked(order);
  };

  displayDeliveryMarkers = () => {
    return this.state.delivery_guys.map((dg, index) => {
      var styleForDG = null;
      if (dg.accept_deliveries.length === 0) {
        styleForDG = { border: "3px solid", color: "#568456" };
      } else if (
        dg.accept_deliveries.length > 0 &&
        dg.accept_deliveries.length < 4
      ) {
        styleForDG = { border: "3px solid", color: "#ff5722" };
      } else if (dg.accept_deliveries.length >= 4) {
        styleForDG = { border: "3px solid", color: "#f44336" };
      }

      return (
        <div lat={dg.latitude} key={index} lng={dg.longitude}>
          <Avatar
            src={"https://fleet.howincloud.com" + dg.image}
            name={dg.name}
            size={40}
            style={styleForDG}
            onClick={() => this.deployDeliveryGuyPop(dg)}
          />

          <Pill
            display='inline-flex'
            style={{
              margin: "0px",
              position: "relative",
              top: "-44px",
              right: "-25px",
            }}
            color='black'>
            {dg.accept_deliveries.length}
          </Pill>
        </div>
      );
    });
  };

  checkSelectedOrder = (order) => {
    return this.state.markedOrders.some(function (el) {
      return el.id === order.id;
    });
  };

  renderToolTip = (order, show_agent) => {
    return (
      <div>
        <span>{order.client_order_identifier}</span> <br />{" "}
        <span>
          {localStorage.getItem("pickup")} : {order.pickup_name}
        </span>{" "}
        <br />{" "}
        {show_agent != false && (
          <>
            <span>
              {localStorage.getItem("agent")} :{" "}
              {order.agent ? (
                order.agent.name
              ) : (
                <>{localStorage.getItem("not_assigned")}</>
              )}
            </span>
            <br />{" "}
          </>
        )}
        <span>
          {localStorage.getItem("created_at")} :{" "}
          {moment(order.created_at).fromNow()}
        </span>
        <br />
        <span>
          {localStorage.getItem("drop_contact")} : {order.customer_phone}
        </span>
        <br />
        <span>
          {localStorage.getItem("drop_pin")} : {order.delivery_pin}
        </span>
        <br />
        <span>
          {localStorage.getItem("collection")} : {order.order_cod}
        </span>
        <br />
        <span>
          {localStorage.getItem("location")} : {order.pickup_address}
        </span>
        <br />
      </div>
    );
  };

  displayOrderMarkers = () => {
    return this.state.orders.map((order, index) => {
      return (
        (order.order_status_id == 5 || order.order_status_id == 3) && (

          <div
            key={index}
            id={index}
            lat={order.drop_lat}
            lng={order.drop_lng}
            onClick={() => {
              this.addToMarked(order);
              this.deployOrderPop(order);
            }}>
            {this.state.showLateOrder ? (
              <Avatar
                src={
                  this.checkSelectedOrder(order)
                    ? "https://eatiko.com/assets/delivery_manager/selected_order_new.png"
                    : this.lateOrderCheck(order)
                      ? "https://fleet.howincloud.com/assets/icons/warning.png"
                      : "https://eatiko.com/assets/delivery_manager/new_order.png"
                }
                size={40}
              />
            ) : (
              <Tooltip placement='topLeft' title={this.renderToolTip(order)}>
                <Avatar
                  src={
                    this.checkSelectedOrder(order)
                      ? "https://eatiko.com/assets/delivery_manager/selected_order_new.png"
                      : "https://eatiko.com/assets/delivery_manager/new_order.png"
                  }
                  size={40}
                />
              </Tooltip>
            )}
          </div>)
      );
    });
  };

  displayOrderPickupMarkers = () => {
    return this.state.orders.map((order, index) => {
      return (
        (order.order_status_id == 5 || order.order_status_id == 3) && (

          <div
            key={index}
            id={index}
            lat={order.pickup_lat}
            lng={order.pickup_lng}
            onClick={() => {
              this.addToMarked(order);
              this.deployOrderPop(order);
            }}>

            <Avatar
              src={"https://fleet.howincloud.com/assets/icons/warning.png"}
              size={40}
            />

          </div>)
      );
    });
  };





  selectTeam = () => {
    return null;
  };

  displayOnGoingOrderMarkers = () => {
    return this.state.on_going_orders.map((order, index) => {
      return (
        <div
          key={index}
          id={index}
          lat={order.drop_lat}
          lng={order.drop_lng}
          onClick={() => {
            this.addToMarked(order);
            this.deployOrderPop(order);
          }}>
          {this.state.showLateOrder ? (
            <Tooltip placement='top' title={this.renderToolTip(order)}>
              <Avatar
                src={
                  this.lateOrderCheck(order)
                    ? "https://fleet.howincloud.com/assets/icons/warning.png"
                    : "https://fleet.howincloud.com/assets/icons/on_going.png"
                }
                size={40}
              />
            </Tooltip>
          ) : (
            <Tooltip placement='top' title={this.renderToolTip(order)}>
              <Avatar
                src={"https://fleet.howincloud.com/assets/icons/on_going.png"}
                size={40}
              />
            </Tooltip>
          )}
        </div>
      );
    });
  };

  handleCity = (item) => {
    this.setState({
      selectedCity: item,
      loading: true,
    });

    this.callApis(item);
  };

  handleTab = (tab) => {
    this.setState({ tab: tab });
  };

  searchData = (pattern) => {
    if (!pattern) {
      this.setState({
        filterdDG: this.state.delivery_guys,
      });
      return;
    }

    const fuse = new Fuse(this.state.delivery_guys, {
      keys: ["name", "phone"],
    });

    const result = fuse.search(pattern);
    const matches = [];
    if (!result.length) {
      this.setState({
        filterdDG: [],
      });
    } else {
      result.forEach(({ item }) => {
        matches.push(item);
      });
      this.setState({
        filterdDG: matches,
      });
    }
  };

  searchOrders = (pattern) => {
    if (!pattern) {
      this.setState({
        filterdOrders: this.state.orders,
      });
      return;
    }

    const fuse = new Fuse(this.state.orders, {
      keys: [
        "auto_assign_status",
        "id",
        "client_order_identifier",
        "customer_name",
        "pickup_name",
      ],
    });

    const result = fuse.search(pattern);
    const matches = [];
    if (!result.length) {
      this.setState({
        filterdOrders: [],
      });
    } else {
      result.forEach(({ item }) => {
        matches.push(item);
      });
      this.setState({
        filterdOrders: matches,
      });
    }
  };

  checkDelayTiming(order) {
    // start time and end time
    var startTime = moment(order.created_at);
    var endTime = moment(new Date());

    // calculate total duration
    var duration = moment.duration(endTime.diff(startTime));
    // var minutes = parseInt(duration.asMinutes())%60;
    var hours = parseInt(duration.asHours());
    var minutes = parseInt(duration.asMinutes()) % 60;

    var finalMinute = hours * 60 + minutes;

    if (finalMinute >= 15) {
      return false;
    } else {
      return true;
    }
  }

  lateOrderCheck(order) {
    // start time and end time
    var startTime = moment(order.created_at);
    var endTime = moment(new Date());

    // calculate total duration
    var duration = moment.duration(endTime.diff(startTime));
    // var minutes = parseInt(duration.asMinutes())%60;
    var hours = parseInt(duration.asHours());
    var minutes = parseInt(duration.asMinutes()) % 60;

    var finalMinute = hours * 60 + minutes;

    if (finalMinute > 45) {
      return false;
    } else {
      return true;
    }
  }

  intentRangeType(score) {
    if (score == 0) {
      return "none";
    } else if (score > 0 && score <= 50) {
      return "success";
    } else if (score > 50 && score <= 80) {
      return "warning";
    } else if (score > 80) {
      return "danger";
    }
  }

  closeCreateTask = () => {
    this.setState({ create_task_pop: false })
    
  }

  openCreateTask = () => {
    this.setState({ create_task_pop: true })
  }
  render() {
    if (
      !sessionStorage.getItem("auth_token") &&
      !sessionStorage.getItem("domain") &&
      !sessionStorage.getItem("client_token")
    ) {
      return <Redirect to='/session-expierd' />;
    }

    return (
      <React.Fragment>
        {/* <div
          onClick={() =>
            this.setState({
              route_optimize_pop: true,
            })
          }
          style={{
            position: "fixed",
            bottom: "4vh",
            zIndex: "9",
            backgroundColor: "rgb(255 255 255)",
            padding: "10px",
            borderRadius: "10px",
            fontWeight: "bold",
            color: "#317bc0",
            left: "45vw",
          }}>
          <img
            src={"https://fleet.howincloud.com/assets/2998762.png"}
            style={{ width: "27px", marginRight: "10px" }}
            alt='route-optimize-fleet'
          />
          Route Optimize ({this.state.unassigned_orders?.length})
        </div> */}
        <Drawer
          title={"Optimize Unassigned Tasks"}
          placement='left'
          width={"500"}
          onClose={() => this.setState({ route_optimize_pop: false })}
          visible={this.state.route_optimize_pop}>
          <RouteOptimization
            unassigned_orders={this.state.unassigned_orders}
            agentsArray={[
              ...this.state.freeAgentsArray,
              ...this.state.busyAgentsArray,
            ]}
            setMapRoute={(data) => this.setMapRoute(data)}
            freeAgentsArray={this.state.freeAgentsArray}
            busyAgentsArray={this.state.busyAgentsArray}
          />
        </Drawer>
        {/* {this.state.route_optimize_pop ? <div>dsfsd</div> : null} */}
        <div
          style={{
            height: "6vh",
            display: "flex",
            background: "rgb(255 255 255)",
            width: "100%",
            alignItems: "center",
            padding: "10px",
            justifyContent: "space-between",
          }}>
          {sessionStorage.getItem("is_whie_label") == 1 ? (
            <a href='#' target='_blank'>
              <div style={{ textAlign: "right", width: "63%" }}>
                <img
                  style={{ width: "150px" }}
                  alt={"logo"}
                  src={`https://fleet.howincloud.com/assets/tenant/${sessionStorage.getItem("tenant")}/logo-white.png`}
                />
              </div>
            </a>
          ) : (
            <a
              href={
                "https://fleet.howincloud.com/client/" +
                sessionStorage.getItem("tenant")
              }
              target='_blank'>
              <div style={{ textAlign: "right", width: "63%" }}>
                <img
                  style={{ width: "150px" }}
                  alt={"logo"}
                  src={"https://howinfleet.com/assets/logos/logo.png"}
                />
              </div>
            </a>
          )}

          <div className='d-flex align-items-center'>
            <div>
              <button
                onClick={() => this.changeLanguage()}
                style={{
                  background: "white",
                  border: "none",
                  color: "black",
                  marginRight: "10px",
                }}
                className='btn btn-sm btn-dark'>
                {localStorage.getItem("lang")
                  ? localStorage.getItem("lang") == "en"
                    ? "العربية"
                    : "English"
                  : "العربية"}
              </button>
            </div>

            <div
              className='header-button'
              onClick={() =>
                this.setState({ create_task_pop: !this.state.create_task_pop })
              }>
              <FeatherIcon icon='plus-square' color='white' size='18px' /> {localStorage.getItem("add_task")}
            </div>

            <div
              className='header-button'
              onClick={() =>
                this.setState({ show_settings: !this.state.show_settings })
              }>
              <FeatherIcon icon='settings' color='white' size='18px' /> {localStorage.getItem("manage")}
            </div>
            <div
              className='header-button'
              onClick={() =>
                this.setState(
                  { loading: true },
                  this.callApis(this.state.selectedCity)
                )
              }>
              <FeatherIcon icon='refresh-cw' color='white' size='18px' />{" "}
              {localStorage.getItem("refresh")}
              <div
                style={{
                  position: "absolute",
                  right: "-4px",
                  top: "-3px",
                  zIndex: "10",
                  backgroundColor: "rgb(255, 50, 50)",
                  padding: "0px 4px",
                  borderRadius: "15px",
                  fontSize: "10px",
                  color: "white",
                }}>
                {this.state.refresh_data_count}
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex'>
          <div style={{ width: "25%" }}>
            {/* <div style={{height: '15vh'}}>
          Ramba Hoi
          </div> */}
            <Tasks
              searchOrders={this.searchOrders}
              orders={this.state.orders}
              filterdOrders={this.state.filterdOrders}
              deployOrderPop={this.deployOrderPop}
              retryAutoAssign={this.retryAutoAssign}
              manaualOrderComplete={this.manaualOrderComplete}
              selectedOrder={this.state.selectedOrder}
              autoMode={this.state.autoMode}
              data_feeds={this.state.data_feeds}
            />
          </div>
          <div style={{ width: "50%" }} className='map-container'>
            <GoogleMapReact
              //bootstrapURLKeys={{ key: 'AIzaSyCnahw85kohHL_-Olc0xhVEzYMrw6KyIxI' }}
              defaultCenter={this.state.center}
              defaultZoom={this.state.zoom}
              options={{
                // styles: exampleMapStyles,
                // mapTypeControl: true,
              }}
              layerTypes={this.state.map_layers ? ["TrafficLayer"] : []}
              onGoogleApiLoaded={({ map, maps }) => (this.ref = map)}>
              {this.state.delivery_guys.length > 0
                ? this.displayDeliveryMarkers()
                : null}

              {this.state.showLatest && this.state.orders.length > 0
                ? this.displayOrderMarkers()
                : null}

              {this.state.showLatest && this.state.orders.length > 0
                ? this.displayOrderPickupMarkers()
                : null}


              {this.state.showOnGoing && this.state.on_going_orders.length > 0
                ? this.displayOnGoingOrderMarkers()
                : null}
            </GoogleMapReact>
          </div>

          <div style={{ width: "25%" }}>
            <Agents
              searchData={this.searchData}
              agents={this.state.delivery_guys}
              filterdDG={this.state.filterdDG}
              deployDeliveryGuyPop={this.deployDeliveryGuyPop}
              mapChangeLocation={this.mapChangeLocation}
              offline_agents={this.state.offline_agents}
            />
          </div>
        </div>
        {this.state.batchMode === true && (
          <Fade top>
            <div
              style={{
                position: "fixed",
                top: "72px",
                right: "25.5%",
                backgroundColor: "white",
                padding: "10px",
                borderRadius: "10px",
                zIndex: "999999999",
                minWidth: "20%",
              }}>
              {this.state.selectedDeliveryGuy ? (
                <div className='d-flex'>
                  <div style={{ width: "26%" }}>
                    <Avatar
                      src={
                        "https://fleet.howincloud.com" +
                        this.state.selectedDeliveryGuy.image
                      }
                      name={this.state.selectedDeliveryGuy.name}
                      size={50}
                    />
                  </div>
                  <div style={{ width: "56%" }}>
                    <p className='mb-0'>
                      {this.state.selectedDeliveryGuy.name}
                    </p>
                    <p className='mb-0 text-muted'>
                      {localStorage.getItem("on_going")}:{" "}
                      {this.state.selectedDeliveryGuy.accept_deliveries.length}
                    </p>
                  </div>
                  <div style={{ width: "15%" }}>
                    {this.state.selectedDeliveryGuy.accept_deliveries.length ===
                      0 && (
                        <Badge color='green'>
                          {localStorage.getItem("free")}
                        </Badge>
                      )}
                    {this.state.selectedDeliveryGuy.accept_deliveries.length >
                      0 &&
                      this.state.selectedDeliveryGuy.accept_deliveries.length <
                      4 && (
                        <Badge color='orange'>
                          {localStorage.getItem("busy")}
                        </Badge>
                      )}
                    {this.state.selectedDeliveryGuy.accept_deliveries.length >=
                      4 && (
                        <Badge color='red'>
                          {localStorage.getItem("danger")}
                        </Badge>
                      )}
                  </div>
                </div>
              ) : (
                <React.Fragment>
                  <div className='d-flex'>
                    <img src='https://eatiko.com/assets/delivery_manager/d-guy_new.png' />
                    <div style={{ margin: "auto", padding: "10px" }}>
                      {localStorage.getItem("select_an_agent")}
                    </div>
                  </div>
                </React.Fragment>
              )}

              {this.state.markedOrders.length > 0
                ? this.state.markedOrders.map((order, index) => (
                  <div className='d-flex card-hype'>
                    <span style={{ width: "50%" }}>
                      #
                      {order.client_order_identifier
                        ? order.client_order_identifier
                        : order.order_client_id
                          ? order.order_client_id
                          : order.id}
                    </span>
                    <span
                      style={{
                        width: "50%",
                        fontSize: "12px",
                        paddingTop: "4px",
                      }}>
                      {order.pickup_name}
                    </span>
                  </div>
                ))
                : null}
              <div className='d-flex'>
                {this.state.markedOrders.length != 0 ? (
                  <Button
                    marginRight={16}
                    onClick={() =>
                      this.setState({
                        markedOrders: [],
                      })
                    }
                    appearance='primary'
                    intent='danger'>
                    {localStorage.getItem("clear")}
                  </Button>
                ) : (
                  <React.Fragment>
                    <div className='d-flex mt-2'>
                      <img src='https://eatiko.com/assets/delivery_manager/empty.png' />
                      <div style={{ margin: "auto", padding: "10px" }}>
                        {localStorage.getItem("select_tasks")}
                      </div>
                    </div>
                  </React.Fragment>
                )}

                {this.state.markedOrders.length > 1 &&
                  this.state.selectedDeliveryGuy && (
                    <Button
                      marginRight={16}
                      onClick={() => this.executeBatchOrder(true)}
                      appearance='primary'
                      intent='danger'>
                      {localStorage.getItem("execute_batch")}
                    </Button>
                  )}

                {this.state.markedOrders.length === 1 &&
                  this.state.selectedDeliveryGuy && (
                    <Button
                      marginRight={16}
                      onClick={() => this.executeBatchOrder(false)}
                      appearance='primary'
                      intent='danger'>
                      {localStorage.getItem("execute_task")}
                    </Button>
                  )}
              </div>
            </div>
          </Fade>
        )}

        {this.state.deliveryPop === true && (
          <Fade bottom>
            <div className='dg-selector'>
              {this.state.selectedDeliveryGuy ? (
                <React.Fragment>
                  <div
                    style={{
                      textAlign: "right",
                    }}
                    onClick={() => this.setState({ deliveryPop: false })}>
                    <FeatherIcon icon='minimize-2' />
                  </div>
                  <div className='d-flex mb-4'>
                    <div style={{ width: "13%" }}>
                      <Avatar
                        marginLeft={10}
                        marginTop={5}
                        src={
                          "https://fleet.howincloud.com" +
                          this.state.selectedDeliveryGuy.image
                        }
                        name={this.state.selectedDeliveryGuy.name}
                        size={50}
                      />
                    </div>
                    <div className='p-2' style={{ width: "30%" }}>
                      <p className='mb-0 font-b'>
                        {this.state.selectedDeliveryGuy.name}
                      </p>
                      <p className='mb-0 text-muted'>
                        {this.state.selectedDeliveryGuy.phone}
                      </p>
                    </div>

                    <div
                      className='p-2'
                      style={{ width: "57%", textAlign: "end" }}>
                      {this.state.selectedDeliveryGuy.accept_deliveries.map(
                        (order, index) => (
                          <React.Fragment>
                            <Tooltip title={this.renderToolTip(order, false)}>
                              <div className='p-2' style={{ fontSize: "13px" }}>
                                {order.client_order_identifier} |{" "}
                                {order.pickup_name}
                              </div>
                            </Tooltip>
                          </React.Fragment>
                        )
                      )}
                    </div>
                  </div>
                </React.Fragment>
              ) : null}
              <hr />
              {this.state.batchMode === true && (
                <Button
                  onClick={() => this.executeBatchOrder()}
                  marginLeft={15}
                  appearance='primary'>
                  {localStorage.getItem("execute_batch_order")}
                </Button>
              )}
              {this.state.selectedDeliveryGuy.on_duty ? (
                <Button
                  marginLeft={15}
                  appearance='primary'
                  intent='danger'
                  onClick={() =>
                    this.markAsInActive(this.state.selectedDeliveryGuy)
                  }>
                  {localStorage.getItem("mark_as_inactive")}
                </Button>
              ) : (
                <Button
                  marginLeft={15}
                  appearance='primary'
                  intent='success'
                  onClick={() =>
                    this.markAsActive(this.state.selectedDeliveryGuy)
                  }>
                  {localStorage.getItem("mark_as_active")}
                </Button>
              )}
            </div>
          </Fade>
        )}

        {this.state.orderPop == true && (
          <Drawer
            title={this.state.selectedOrder.client_order_identifier}
            placement='left'
            width={"378"}
            onClose={() =>
              this.setState({ orderPop: false, show_rejections: false })
            }
            visible={true}>
            {this.state.selectedOrder ? (
              <React.Fragment>
                {this.state.selectedOrder.auto_assign_status ===
                  "waitingapproval" && (
                    <div
                      className='auto-assign-ongoing-div'
                      style={{
                        backgroundColor: "#faad14",
                        borderRadius: "10px",
                        padding: "9px",
                      }}>
                      <span style={{ marginRight: "10px" }}>
                        {localStorage.getItem("waiting_for_approval")}
                      </span>{" "}
                    </div>
                  )}
                {this.state.selectedOrder.auto_assign_status === "ongoing" && (
                  <div
                    className='auto-assign-ongoing-div'
                    style={{ borderRadius: "10px", padding: "9px" }}>
                    <span style={{ marginRight: "10px" }}>
                      {localStorage.getItem("auto_assigning")}
                    </span>{" "}
                    <Spinner color={"red"} size={16} />
                  </div>
                )}
                {this.state.selectedOrder.auto_assign_status === "assigned" && (
                  <div
                    className='auto-assign-div'
                    style={{ borderRadius: "10px", padding: "9px" }}>
                    <span style={{ marginRight: "10px" }}>
                      {localStorage.getItem("order_assigned_to")} :{" "}
                      {this.state.selectedOrder.agent.name}
                    </span>
                    {localStorage.getItem("show_ex") == "true" &&
                      this.state.selectedOrder.order_status_id == 7 && (
                        <Tooltip
                          placement='top'
                          title={localStorage.getItem(
                            "complete_the_order_manually"
                          )}>
                          <FeatherIcon
                            size={13}
                            onClick={() =>
                              this.manaualOrderComplete(
                                this.state.selectedOrder
                              )
                            }
                            icon={"check-circle"}
                          />
                        </Tooltip>
                      )}
                  </div>
                )}
                {this.state.selectedOrder.auto_assign_status === "failed" && (
                  <div
                    className='auto-assign-div-failed'
                    style={{ borderRadius: "10px", padding: "9px" }}>
                    <span style={{ marginRight: "10px" }}>
                      {localStorage.getItem("auto_assign_failed")}
                    </span>
                  </div>
                )}
                <div className='d-flex mb-4'>
                  <div style={{ fontWeight: "bold" }}>
                    <Badge color='red'>
                      <Moment fromNow={true}>
                        {this.state.selectedOrder.created_at}
                      </Moment>
                    </Badge>{" "}
                  </div>
                </div>

                <Divider orientation='left' plain>
                  <b>{localStorage.getItem("task_details")} </b>
                </Divider>

                <div style={{ fontSize: "16px" }}>
                  {this.state.selectedOrder.pickup_dimension == "single" ? (
                    <div className='mb-2'>
                      <FeatherIcon icon='map-pin' size='16' />{" "}
                      {localStorage.getItem("pickup")} :{" "}
                      <span style={{ fontWeight: "700" }}>
                        {this.state.selectedOrder.pickup_name}
                      </span>
                    </div>
                  ) : (
                    <>
                      {this.state.selectedOrder.pickups.map((pickup) => (
                        <div className='mb-2'>
                          <FeatherIcon icon='map-pin' size='16' />{" "}
                          {localStorage.getItem("pickup")} :{" "}
                          <span style={{ fontWeight: "700" }}>
                            {pickup.name} | {pickup.contact}
                          </span>
                        </div>
                      ))}
                    </>
                  )}

                  <div className='mb-2'>
                    <FeatherIcon icon='user' size='16' />{" "}
                    {localStorage.getItem("agent")} :{" "}
                    <b>
                      {this.state.selectedOrder.agent ? (
                        this.state.selectedOrder.agent.name
                      ) : (
                        <Badge color='red' marginRight={8}>
                          Not assigned
                        </Badge>
                      )}
                    </b>
                  </div>
                  {this.state.selectedOrder?.customer !== null && 
                  
                  <div className='mb-2'>
                    <FeatherIcon icon='user' size='16' />{" "}
                    customer
                    <span style={{ fontWesight: "700" }}>
                      {this.state.selectedOrder.customer?.name}-{this.state.selectedOrder.customer?.customer_id}
                    </span>
                  </div>
                  }
                  <div className='mb-2'>
                    <FeatherIcon icon='phone' size='16' />{" "}
                    {localStorage.getItem("drop_contact")}:{" "}
                    <span style={{ fontWesight: "700" }}>
                      {this.state.selectedOrder.customer_phone}{" "}
                    </span>
                  </div>
                  <div className='mb-2'>
                    <FeatherIcon icon='unlock' size='16' />{" "}
                    {localStorage.getItem("drop_pin")} :{" "}
                    <span style={{ fontWeight: "700" }}>
                      {this.state.selectedOrder.delivery_pin}
                    </span>
                  </div>
                  <div className='mb-2'>
                    <FeatherIcon icon='dollar-sign' size='16' />{" "}
                    {localStorage.getItem("cod_collection")} :{" "}
                    {this.state.selectedOrder.order_cod
                      ? this.state.selectedOrder.order_cod
                      : "0.00"}
                  </div>
                  <dsiv className='mb-2'>
                    <FeatherIcon icon='map' size='16' />{" "}
                    {localStorage.getItem("drop_loction")} :{" "}
                    <span style={{ fontWeight: "700" }}>
                      {this.state.selectedOrder.customer_address}
                    </span>
                  </dsiv>
                </div>
                <Divider orientation='left' plain>
                  <b>{localStorage.getItem("available_actions")} </b>
                </Divider>

                <div className='d-flex'>
                  {this.state.selectedOrder.auto_assign_status === "failed" && (
                    <Button
                      onClick={() =>
                        this.retryAutoAssign(this.state.selectedOrder)
                      }
                      appearance='primary'
                      intent='danger'>
                      {localStorage.getItem("retry_auto_assignation")}
                    </Button>
                  )}

                  <Button
                    onClick={() =>
                      this.dispatchUpdateToClient(this.state.selectedOrder)
                    }
                    // marginLeft={15}
                    appearance='primary'
                    intent='danger'>
                    {localStorage.getItem("force_client_update")}
                  </Button>
                  {this.state.selectedOrder.order_status_id == 7 && (
                    <Button
                      marginLeft={15}
                      appearance='success'
                      onClick={() =>
                        this.manaualOrderComplete(this.state.selectedOrder)
                      }>
                      {localStorage.getItem("manual_order_complete")}
                    </Button>
                  )}
                </div>

                <Divider
                  orientation='left'
                  plain
                  onClick={() =>
                    this.setState({
                      show_rejections: !this.state.show_rejections,
                    })
                  }>
                  <b>
                    {localStorage.getItem("agent_rejections")} (
                    {this.state.selectedOrder.rejections.length})
                  </b>
                </Divider>
                {this.state.show_rejections && (
                  <div>
                    {this.state.selectedOrder.rejections.map(
                      (reject, index) => (
                        <div className='d-flex justify-content-between'>
                          <div>{reject.user.name}</div>
                          <div>{reject.agent_reason}</div>
                        </div>
                      )
                    )}
                  </div>
                )}

                <Divider orientation='left' plain>
                  <b>Track Task</b>
                </Divider>

                <Button
                  appearance={"primary"}
                  onClick={() =>
                    window.open(
                      `https://ev.howincloud.com/track-my-task/${sessionStorage.getItem(
                        "tenant"
                      )}/${this.state.selectedOrder.client_order_identifier}`,
                      "_blank"
                    )
                  }>
                  Goto Tracking Page
                </Button>
              </React.Fragment>
            ) : null}
          </Drawer>
        )}

        {this.state.create_task_pop == true && (
          <Drawer
            title={localStorage.getItem("create_new_task")}

            placement='left'
            width={"800"}
            onClose={() => this.setState({ create_task_pop: false })}
            visible={true}>
            <CreateTask
              teams={this.state.cities}
              products={this.state.products}
              warehouses={this.state.warehouses}
              customers={this.state.customers}
              closeCreateTask={this.closeCreateTask}
              openCreateTask={this.openCreateTask}
            />
          </Drawer>
        )}

        {this.state.show_settings && (
          <div className='settings-selector'>
            {/* <div className='d-flex justify-content-between'>
              <span>Show Traffic</span>{" "}
              <Switch
                className='ml-4'
                onChange={(c) => {
                  this.setState({
                    map_layers: c,
                  });
                  localStorage.setItem("map_layers", c);
                }}
                checked={this.state.map_layers}
              />
            </div> */}
            <div className='mt-2 d-flex justify-content-between'>
              <span>{localStorage.getItem("show_on_going")}</span>{" "}
              <Switch
                className='ml-4'
                onChange={(c) => {
                  this.setState({
                    showOnGoing: c,
                  });
                  localStorage.setItem("showOnGoing", c);
                }}
                checked={this.state.showOnGoing}
              />
            </div>

            <div className='mt-2 d-flex justify-content-between'>
              <span>{localStorage.getItem("show_latest")} </span>{" "}
              <Switch
                className='ml-4'
                onChange={(c) => {
                  this.setState({
                    showLatest: c,
                  });
                  localStorage.setItem("show_latest", c);
                }}
                checked={this.state.showLatest}
              />
            </div>
            <div className='mt-2 d-flex justify-content-between'>
              <span>{localStorage.getItem("show_late_tasks")} </span>{" "}
              <Switch
                className='ml-4'
                onChange={(c) => {
                  this.setState({
                    showLateOrder: c,
                  });
                  localStorage.setItem("showLateOrder", c);
                }}
                checked={this.state.showLateOrder}
              />
            </div>
          </div>
        )}

        {this.state.loading && (
          <div
            style={{
              zIndex: "2147483650",
              width: "100%",
              height: "100%",
              top: "0px",
              background: "rgb(255 255 255 / 85%)",
              position: "absolute",
              padding: "auto",
            }}>
            <Spinner style={{ margin: "auto", paddingTop: "48vh" }} />
          </div>
        )}
        <Footer
          handleCity={(e) => this.handleCity(e)}
          cities={this.state.cities}
          selectedCity={this.state.selectedCity}
          batchMode={this.state.batchMode}
          setBatchMode={() =>
            this.setState({
              batchMode: !this.state.batchMode,
              markedOrders: [],
            })
          }
          unassignedOrders={this.state.unassigned_orders.length}
          setRouteOptimizePop={() =>
            this.setState({
              route_optimize_pop: true,
            })
          }
          trafficLayer={this.state.map_layers}
          setTrafficLayer={(e) =>
            this.setState({
              map_layers: e,
            })
          }
        />
      </React.Fragment>
    );
  }
}
const arcCenterRenderer = (value, color) => {
  return (
    <h3
      style={{
        color: color,
      }}>
      {value}%
    </h3>
  );
};
export default GoogleApiWrapper({
  apiKey: "AIzaSyCBmFnNqWbizwxbnfF-6F4hUNp8jh5_RlY",
  //apiKey: ''
})(Portal);
