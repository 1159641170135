import React, { Component } from "react";
import axios from 'axios';
import { Redirect } from "react-router";
import FeatherIcon from "feather-icons-react";
import Moment from "react-moment";
import * as moment from "moment";
import MapSelector from "./MapSelector";
import 'react-best-tabs/dist/index.css';
import toast, { Toaster } from 'react-hot-toast';

import { Form, Input, Button, Space, Divider, Select, InputNumber } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import GoogleMapReact from 'google-map-react';
import { TrashIcon } from "evergreen-ui";
import ReactModal from "react-modal";
import ChooseLocationMap from "./ChooseLocationMap";
const { Option } = Select;

const Marker = ({ text }) => <div>
    <img src={'/assets/drop_pin.png'} className=" mx-auto" alt="" style={{ height: '30px' }} />
</div>;

export class CreateTask extends Component {

    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            selectedItems: [],
            showPickupMap: true,
            pickupLocation: null,
            pickupAddress: '',
            pickupWareaHouse: [],
            pikcupType: null,
            showPickupMapModal: false,
            showDropMapModal: false,
            showDropMap: true,
            dropLocation: null,
            dropAddress: '',
            dropCustomer: [],
            dropType: null,
            center: {
                lat: 10.976,
                lng: 76.2254,
            },
            orderCreateErrors: {},
            renderMap: false, // New state variable
            closType: '',
            selectedCustomers: [], // Add a new state variable to track selected customers
            customersToShow: [],

        };
    }

    handleError = (error, input) => {
        this.setState(prevState => ({
            orderCreateErrors: { ...prevState.orderCreateErrors, [input]: error }
        }));
    };

    componentDidMount() {
        this.setState({ customersToShow: this.props.customers })

    }

    checkError = (data) => {

        let isValid = true;
        const { pikcupType, pickupLocation, dropLocation } = this.state;

        if (pikcupType === "other" && !pickupLocation) {
            // Set error for pickupLocation
            this.handleError("Pickup location not selected", "pickupLocation");
            isValid = false;
        } else {
            // Clear error for pickupLocation if it exists
            this.setState(prevState => {
                const newErrors = { ...prevState.orderCreateErrors };
                delete newErrors.pickupLocation;
                return { orderCreateErrors: newErrors };
            });
            isValid = true;
        }

        if (!dropLocation) {
            this.handleError("Drop location is required for custom type", "dropLocation");
            isValid = false;

        } else {
            this.setState(prevState => {
                const newErrors = { ...prevState.orderCreateErrors };
                delete newErrors.dropLocation;
                return { orderCreateErrors: newErrors };
            });
            isValid = true;
        }
        if (data?.items && data?.items?.length > 0) {
            this.setState(prevState => {
                const newErrors = { ...prevState.orderCreateErrors };
                delete newErrors.items;
                return { orderCreateErrors: newErrors };
            });
            isValid = true;
        } else {
            this.handleError("Please add atleast one item to place order", "items");
            isValid = false;


        }

        if (isValid) {
            this.postToServer(data)
        } else {
            console.log('====================================');
            console.log("Error");
            console.log('====================================');
        }
    }
    postToServer = (data) => {
        
       
        data.token = sessionStorage.getItem('auth_token');
        data.customer_id = this.state.dropCustomer?.id;
        if (this.state.pickupLocation) {
            data.pickup_lat = this.state.pickupLocation.lat;
            data.pickup_lng = this.state.pickupLocation.lng;
            data.pickupAddress = this.state.pickupAddress;
        }
        if (this.state.dropLocation) {
            data.drop_lat = this.state.dropLocation.lat;
            data.drop_lng = this.state.dropLocation.lng;
            data.dropAddress = this.state.dropAddress;
            data.dropType = this.state.dropType;

        }
        data.selectedCustomers = this.state.selectedCustomers;

        console.log('====================================');
        console.log(this.state.closType);
        console.log('====================================');
        try {
            axios.post(
                `https://fleet.howincloud.com/api/v1/${sessionStorage.getItem(
                    "tenant"
                )}/create-task`,
                data
            ).then((response) => {
                toast.success('Task Created Successfully!', {
                    duration: 6000, style: {
                        padding: '16px',
                    },
                })

                setTimeout(() => {
                    this.onReset();
                }, 2000);

                this.props.closeCreateTask()
                // window.location.reload();
            });
        } catch (error) {
            toast.error('Oops! Something error happened , please try again', { duration: 6000, })
        }

    }
    // Event handler for onChange
    onChange = (value) => {
        console.log(`selected ${value}`);
    };

    // Event handler for onSearch
    onSearch = (value) => {
        console.log('search:', value);
    };

    // Function to filter options based on input
    filterOption = (input, option) => {
        return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    };

    handleProductSelect = (productId) => {

        // Add the selected product's ID to the selectedItems array
        this.setState(prevState => ({
            selectedItems: [...prevState.selectedItems, productId]
        }));
    }


    handleMapClick = async ({ lat, lng, type }) => {
        const location = { lat, lng };
        try {
            const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`);
            const address = response.data.display_name; // The full address

            if (type === 'pickup') {

                this.setState({
                    pickupLocation: location, pickupAddress: address, center: location // Update the map center
                });

                this.closeMapModal()
                this.closeDropMapModal()
            } else if (type === 'drop') {
                this.setState({
                    dropLocation: location, dropAddress: address, center: location // Update the map center
                });
            }
        } catch (error) {
            console.error('Error fetching address:', error);
            // Handle any errors, such as network issues or no address found
        }
    }



    handleWarehouseChange = (value) => {
        const selectedTeam = this.props.warehouses.find(team => team.id === value);
        if (value === 'other') {
            this.setState({ showPickupMapModal: true, pickupLocation: null, pikcupType: 'other', pickupAddress: "" });
            this.openMapModal()
        } else if (selectedTeam) {

            this.handleMapClick({ lat: selectedTeam.latitude, lng: selectedTeam.longitude, type: 'pickup' })
            this.setState({
                pikcupType: "warehouse",
                pickupWareaHouse: selectedTeam
            });
        } else {
            this.setState({ pickupLocation: null, });
        }
    }

    handleTeamChange = (value) => {
        const selectedTeam = this.props.teams.find(team => team.id === value);

        if (selectedTeam) {
            const filteredCustomers = this.props.customers.filter(customer => customer.team_id === selectedTeam.id);

            this.setState({ customersToShow: filteredCustomers });

            console.log('====================================');
            console.log(this.props.customersToShow);
            console.log('====================================');
        } else {
            console.error('Selected team not found');
        }
    }

    // handleCustomerChange = (value) => {
    //     const selectedCustomer = this.props.customers.find(customer => customer.id === value);
    //     if (value === 'other') {
    //         this.setState({ showDropMap: true, dropLocation: null, dropType: 'other', dropAddress: "" });
    //         this.openDropMapModal()

    //     } else if (selectedCustomer) {
    //         this.handleMapClick({ lat: selectedCustomer.latitude, lng: selectedCustomer.longitude, type: 'drop' })
    //         this.setState({
    //             dropType: "customer",
    //             dropCustomer: selectedCustomer
    //         });
    //     } else {
    //         this.setState({ dropLocation: null, });
    //     }

    // }

    handleCustomerChange = (selected) => {
        // Update state based on selected values
        this.setState({ selectedCustomers: selected });

        // If 'other' is selected, handle as before
        if (selected.includes('other')) {
            this.setState({ showDropMap: true, dropLocation: null, dropType: 'other', dropAddress: "" });
            this.openDropMapModal();
        } else {
            // Assume the last selected customer is the one to use for setting the drop location
            const lastSelectedCustomerId = selected[selected.length - 1];
            const selectedCustomer = this.props.customers.find(customer => customer.id === lastSelectedCustomerId);
            if (selectedCustomer) {
                this.handleMapClick({ lat: selectedCustomer.latitude, lng: selectedCustomer.longitude, type: 'drop' });
                this.setState({
                    dropType: "customer",
                    dropCustomer: selectedCustomer
                });
            } else {
                this.setState({ dropLocation: null, });
            }
        }
    }

    togglePickupMap = () => {
        this.setState(prevState => ({ showPickupMap: !prevState.showPickupMap }));
    }

    toggleDropMap = () => {
        this.setState(prevState => ({ showDropMap: !prevState.showDropMap }));
    }

    openMapModal = () => {
        this.setState({ showPickupMapModal: true }, () => {
            setTimeout(() => {
                this.setState({ renderMap: true });
            }, 500); // Adjust delay as needed
        });
    };

    openDropMapModal = () => {
        this.setState({ showDropMapModal: true }, () => {
            setTimeout(() => {
                this.setState({ renderMap: true });
            }, 500); // Adjust delay as needed
        });
    };

    // Function to close the modal and reset renderMap
    closeMapModal = () => {
        this.setState({ showPickupMapModal: false, renderMap: false });
    };

    closeDropMapModal = () => {
        this.setState({ showDropMapModal: false, renderMap: false });
    };

    renderMap = (type) => {
        const defaultLocation = { lat: -34.397, lng: 150.644 };
        const location = type === 'pickup' ? this.state.pickupLocation : this.state.dropLocation;
        const center = this.state[type + 'Location'] || this.state.center;

        const shouldSetOnClick = type === 'pickup' ? this.state.pikcupType !== 'warehouse' : true;

        return (
            <div style={{ height: '90vh', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyCBmFnNqWbizwxbnfF-6F4hUNp8jh5_RlY' }}
                    defaultCenter={{ lat: 59.95, lng: 30.33 }}
                    defaultZoom={11}
                    center={{ lat: 59.95, lng: 30.33 }}


                    onClick={shouldSetOnClick ? (e) => {
                        this.handleMapClick({ lat: e.lat, lng: e.lng, type });
                    } : null}
                >
                    {this.state.pickupLocation && (
                        <Marker
                            lat={this.state.pickupLocation.lat}
                            lng={this.state.pickupLocation.lng}
                            text={type === 'pickup' ? 'Pickup' : 'Drop'}
                        />
                    )}
                    {/* {location && <Marker lat={location.lat} lng={location.lng} text={type === 'pickup' ? 'Pickup' : 'Drop'} />} */}
                </GoogleMapReact>
            </div>
        );
    }

    renderDropMap = (type) => {
        const defaultLocation = { lat: -34.397, lng: 150.644 };
        const location = this.state.dropLocation;
        const center = this.state.dropLocation || this.state.center;

        const shouldSetOnClick = type === 'drop' ? this.state.dropType !== 'customer' : true;

        return (
            <div style={{ height: '20vh', width: '100%', }}>
                {/* <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyCBmFnNqWbizwxbnfF-6F4hUNp8jh5_RlY' }}
                    defaultCenter={this.state.center}
                    defaultZoom={11}
                    center={center}
                    

                    onClick={shouldSetOnClick ? (e) => {
                        this.handleMapClick({ lat: e.lat, lng: e.lng, type });
                    } : null}
                >
                    {this.state.dropLocation && (
                        <Marker
                            lat={this.state.dropLocation.lat}
                            lng={this.state.dropLocation.lng}
                            text={type === 'pickup' ? 'Pickup' : 'Drop'}
                        />
                    )}
                </GoogleMapReact> */}
            </div>
        );
    }

    onReset = () => {
        this.formRef.current.resetFields();
    };

    render() {
        return (
            <Form onFinish={(e) => this.checkError(e)} autoComplete="off" ref={this.formRef}>
                <Toaster position="bottom-center" />

                <ReactModal
                    isOpen={this.state.showPickupMapModal}
                    onRequestClose={() => this.closeMapModal()}
                    contentLabel="Example Modal"
                    style={{
                        content: {
                            borderRadius: "20px"
                        },
                        overlay: {
                            "background": "linear-gradient(to right, #2a5298, #1e3c72)"
                            // opacity: "0.9"
                        }
                    }}
                    className="bg-white rounded-4 p-3 mx-auto text-center w-100 h-[80vh]"
                    overlayClassName="d-flex align-items-center justify-content-center fixed-top fixed-bottom px-4 bg-dark opacity-10"
                    appElement={document.getElementById("root")}
                >
                    {this.state.renderMap &&

                        <ChooseLocationMap
                            pickupLocation={this.state.pickupLocation}
                            handleMapClick={this.handleMapClick}
                            type="pickup"
                            pikcupType={this.state.pikcupType}
                            closeModal={this.closeMapModal}

                        />}

                </ReactModal>

                <ReactModal
                    isOpen={this.state.showDropMapModal}
                    onRequestClose={() => this.closeDropMapModal()}
                    contentLabel="Example Modal"
                    style={{
                        content: {
                            borderRadius: "20px"
                        },
                        overlay: {
                            "background": "linear-gradient(to right, #2a5298, #1e3c72)"
                            // opacity: "0.9"
                        }
                    }}
                    className="bg-white rounded-4 p-3 mx-auto text-center w-100 h-[80vh]"
                    overlayClassName="d-flex align-items-center justify-content-center fixed-top fixed-bottom px-4 bg-dark opacity-10"
                    appElement={document.getElementById("root")}
                >
                    {this.state.renderMap &&
                        <ChooseLocationMap
                            pickupLocation={this.state.dropLocation}
                            handleMapClick={this.handleMapClick}
                            type="drop"
                            pikcupType={this.state.dropType}
                            closeModal={this.closeDropMapModal}
                        />}

                </ReactModal>
                {/* <div>Basic Details</div>  */}
                <div className="d-flex  align-items-center gap-2 mb-3">
                    <div className="d-flex align-items-center justify-content-center fs-6 fw-bold" style={{ height: '35px', width: '35px', borderRadius: '100px', backgroundColor: '#134780', color: 'white' }}>
                        01
                    </div>
                    <div className="fs-6 fw-bold d-flex align-items-start flex-column">


                        {localStorage.getItem("choose_your_warehouse")}
                        <div className="text-muted  " style={{ fontSize: '10px' }}>
                            {localStorage.getItem("choose_your_warehouse_subtitle")}
                        </div>
                    </div>
                </div>
                <Form.Item name="warehouse_id" rules={[{ required: true, message: 'Team is not selected' }]} style={{ width: '10vw' }}>
                    <Select
                        placeholder={localStorage.getItem("choose_pickup_location_on_map")}
                        allowClear
                        style={{ width: '40vw' }}
                        onChange={(e) => this.handleWarehouseChange(e)}

                    >
                        {this.props.warehouses.map((warehouse) => warehouse.id !== 'all' && (
                            <Option value={warehouse.id}>{warehouse.name}</Option>
                        ))}
                        <Option value="other"> {localStorage.getItem("choose_pickup_location_on_map")}</Option>

                    </Select>
                </Form.Item>
                {/* <Form.Item name="delivery_charge" style={{ width: '10vw' }} rules={[
                        { required: true, message: 'Delivery charge is required, minimum 0' }
                    ]} >
                        <InputNumber placeholder="Task Delivery Charge" style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item name="order_cod" rules={[
                        { required: true, message: 'Delivery charge is required, minimum 0' }
                    ]} style={{ width: '10vw' }}>
                        <InputNumber placeholder="Cash On Delivery Amount" style={{ width: '100%' }} />
                    </Form.Item> */}
                <Divider />
                {this.state.pikcupType?.length > 0 &&


                    <React.Fragment>
                        {/* Pickup location */}
                        <div>

                            <div className="d-flex align-items-center gap-2 mb-3">
                                <div className="d-flex align-items-center justify-content-center fs-6 fw-bold" style={{ height: '35px', width: '35px', borderRadius: '100px', backgroundColor: '#134780', color: 'white' }}>
                                    02
                                </div>
                                {this.state.pikcupType === "other" ? (

                                    <div className="fs-6 fw-bold d-flex align-items-start flex-column">

                                        {localStorage.getItem("pickup_location_setup")}
                                        <div className="text-muted  " style={{ fontSize: '10px' }}>{localStorage.getItem("choose_pickup_location_of_your_order")}</div>
                                    </div>
                                ) : (
                                    <div className="fs-6 fw-bold d-flex align-items-start flex-column">

                                        {localStorage.getItem("pickup_location_setup")} - {this.state.pickupWareaHouse?.name}

                                        <div className="text-muted  " style={{ fontSize: '10px' }}>Pickup Location is setup to the warehouse you have chosen</div>
                                    </div>
                                )}
                            </div>

                            <div className="mt-3"> {localStorage.getItem("pickup_location_address")}  :<br /> <span className="fs-6 fw-bold">{this.state.pickupAddress}</span></div>

                            {this.state.orderCreateErrors?.pickupLocation && (
                                <div >
                                    <h6 className="text-danger text-center" >
                                        {this.state.orderCreateErrors.pickupLocation}
                                    </h6>
                                </div>
                            )}
                            <Divider />
                        </div>
                        {/* Pickup location */}

                        {/* CHoose Customer */}

                        <div className="d-flex  align-items-center gap-2 mb-3">
                            <div className="d-flex align-items-center justify-content-center fs-6 fw-bold" style={{ height: '35px', width: '35px', borderRadius: '100px', backgroundColor: '#134780', color: 'white' }}>
                                03
                            </div>
                            <div className="fs-6 fw-bold d-flex align-items-start flex-column">

                                Choose Team
                                <div className="text-muted" style={{ fontSize: '10px' }}>{localStorage.getItem("choose_your_warehouse_where_the_agent_will_pickup_the_order")} </div>
                            </div>
                        </div>

                        <Space style={{ display: 'flex', }} align="inline">

                            <Form.Item name="team_id" rules={[{ required: true, message: 'Team is not selected' }]} style={{ width: '10vw' }}>
                                <Select
                                    placeholder={localStorage.getItem("choose_pickup_location_on_map")}
                                    allowClear
                                    style={{ width: '40vw' }}
                                    onChange={(e) => this.handleTeamChange(e)}

                                >
                                    {this.props.teams.map((warehouse) => warehouse.id !== 'all' && (
                                        <Option value={warehouse.id}>{warehouse.name}</Option>
                                    ))}
                                    <Option value="other"> {localStorage.getItem("choose_pickup_location_on_map")}</Option>

                                </Select>
                            </Form.Item>
                        </Space>
                        {/* CHoose Customer */}

                        <div className="d-flex  align-items-center gap-2 mb-3">
                            <div className="d-flex align-items-center justify-content-center fs-6 fw-bold" style={{ height: '35px', width: '35px', borderRadius: '100px', backgroundColor: '#134780', color: 'white' }}>
                                04
                            </div>
                            <div className="fs-6 fw-bold d-flex align-items-start flex-column">

                                {localStorage.getItem("choose_customer")}
                                <div className="text-muted  " style={{ fontSize: '10px' }}>{localStorage.getItem("choose_your_warehouse_where_the_agent_will_pickup_the_order")} </div>
                            </div>
                        </div>
                        <Space style={{ display: 'flex', }} align="inline">

                            <Form.Item name="customer_id" rules={[{ required: true, message: 'Customer is not selected' }]} style={{ width: '20vw' }}>
                                <Select
                                    mode="multiple"
                                    placeholder="Choose pickup location"
                                    allowClear
                                    style={{ width: '20vw' }}
                                    onChange={this.handleCustomerChange}
                                    value={this.state.selectedCustomers}


                                >
                                    {this.state.selectedCustomers.length === 0 && <Option value="other">{localStorage.getItem("choose_drop_location")} </Option>}

                                    {!this.state.selectedCustomers.includes('other') && this.state.customersToShow.filter(customer => !this.state.selectedCustomers.some(sc => sc.value === customer.id.toString())).map((customer) => (
                                        <Option key={customer.id} value={customer.id.toString()}>{customer.name}</Option>
                                    ))}

                                </Select>
                            </Form.Item>
                            {this.state.dropType == "other" &&
                                <>

                                    <Form.Item style={{ width: '10vw' }} name="customer_name" rules={[{ required: true, message: 'Customer name is missing' }]}>
                                        <Input placeholder="Customer name" />
                                    </Form.Item>

                                    <Form.Item style={{ width: '10vw' }} name="customer_phone" rules={[{ required: true, message: 'Customer phone is missing' }]}>
                                        <Input placeholder="Customer phone" />
                                    </Form.Item>
                                </>
                            }
                        </Space>
                        {/* Drop location */}
                        {/* {this.state.dropType?.length > 0 && */}
                        <React.Fragment>

                            <div>
                                <div className="d-flex  align-items-center gap-2 mb-3">
                                    <div className="d-flex align-items-center justify-content-center fs-6 fw-bold" style={{ height: '35px', width: '35px', borderRadius: '100px', backgroundColor: '#134780', color: 'white' }}>
                                        05
                                    </div>
                                    <div className="fs-6 fw-bold d-flex align-items-start flex-column">

                                        {localStorage.getItem("choose_drop_location")}
                                        <div className="text-muted  " style={{ fontSize: '10px' }}> {localStorage.getItem("choose_drop_location_of_your_order")} </div>
                                    </div>
                                </div>
                                {this.state.selectedCustomers.includes('other') ? (

                                    <div className="mt-3">Drop Location Address  :<br /> <span className="fs-6 fw-bold">{this.state.dropAddress}</span></div>
                                ) : (
                                    <>
                                        {this.state.selectedCustomers?.length !== 0 &&

                                            <div className="mt-3"> <span className="fs-6 fw-bold text-danger">{localStorage.getItem("drop_location_warning")}</span></div>
                                        }
                                    </>

                                )}

                                {this.state.orderCreateErrors?.dropLocation && (
                                    <div >
                                        <h6 className="text-danger text-center" >
                                            {this.state.orderCreateErrors.dropLocation}
                                        </h6>
                                    </div>
                                )}
                                <Divider />

                            </div>

                            {/* Drop location */}

                            {/* Choose Items */}
                            <div>

                                <div className="d-flex  align-items-center gap-2 mb-3">
                                    <div className="d-flex align-items-center justify-content-center fs-6 fw-bold" style={{ height: '35px', width: '35px', borderRadius: '100px', backgroundColor: '#134780', color: 'white' }}>
                                        06
                                    </div>
                                    <div className="fs-6 fw-bold d-flex align-items-start flex-column">

                                        {localStorage.getItem("choose_items")}
                                        <div className="text-muted  " style={{ fontSize: '10px' }}>{localStorage.getItem("choose_items_and_enter_quantity_for_each_items")}
                                        </div>
                                    </div>
                                </div>
                                <Form.List name="items">
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(({ key, name, ...restField }) => (
                                                <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="">
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'product_id']}
                                                        style={{ width: "20vw" }}
                                                        rules={[{ required: true, message: 'Product is required' }]}
                                                    >

                                                        <Select
                                                            showSearch
                                                            placeholder={localStorage.getItem("select_a_product")}

                                                            optionFilterProp="children"
                                                            name={[name, 'product_id']}
                                                            className="text-capitalize"
                                                            onSearch={this.onSearch}
                                                            style={{ width: "20vw" }}
                                                            filterOption={this.filterOption}

                                                            options={this.props.products
                                                                .filter(product => !this.state.selectedItems.includes(product.id.toString()))
                                                                .map(product => ({
                                                                    value: product.id.toString(),
                                                                    label: product.name
                                                                }))
                                                            }
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'quantity']}
                                                        style={{ width: "13vw" }}
                                                        rules={[{ required: true, message: 'Missing Quantity Min 1' }]}
                                                    >
                                                        <Input placeholder={localStorage.getItem("quantity")} />
                                                    </Form.Item>
                                                    <Button className="d-flex align-items-center justify-content-center fs-6 fw-bold" style={{ backgroundColor: '#ff0000', borderRadius: '4px', color: 'white', width: "10vw", outline: 'none' }} onClick={() => remove(name)} block icon={<TrashIcon />}>
                                                        &nbsp;                                                 {localStorage.getItem("remove")}

                                                    </Button>
                                                    {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                                                </Space>
                                            ))}
                                            <Form.Item>
                                                <Button className="d-flex align-items-center justify-content-center fs-6 fw-bold" style={{ backgroundColor: '#134780', borderRadius: '4px', color: 'white', outline: 'none' }} onClick={() => add()} block icon={<PlusOutlined />}>
                                                    {localStorage.getItem("click_to_add_items")}
                                                </Button>
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                                {this.state.orderCreateErrors?.items && (
                                    <div >
                                        <h6 className="text-danger text-center" >
                                            {this.state.orderCreateErrors.items}
                                        </h6>
                                    </div>
                                )}
                            </div>
                            {/* Choose Items */}



                            <div>
                                <div> {localStorage.getItem("add_instruction_notes_for_agent")}</div>
                                <Form.Item name={'Address'} >
                                    <Input.TextArea placeholder="Do something like this for this order" />
                                </Form.Item>
                                <Divider />

                            </div>

                            <Form.Item>
                                <Button onClick={() => this.setState({ closType: 'close' })} className="d-flex align-items-center justify-content-center fs-6 fw-bold" style={{ backgroundColor: '#134780', borderRadius: '4px', color: 'white', outline: 'none', }} htmlType="submit" block >
                                    {localStorage.getItem("create_new_task")}
                                </Button>
                                {/* <Button  onClick={() => this.setState({closType:'open_again'})}  className="d-flex align-items-center justify-content-center fs-6 fw-bold" style={{ backgroundColor: '#134780', borderRadius: '4px', color: 'white', outline: 'none',width:'20vw' }} htmlType="submit" block >
                                            Create New Task & Add More
                                        </Button> */}
                            </Form.Item>
                        </React.Fragment>
                        {/* } */}
                    </React.Fragment>
                }
            </Form>
        );
    }

}

export default CreateTask;